import { Method } from 'axios';

export const RECAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/enterprise.js?render=';

export const getSilentConfig = (method: Method, url: string, token?: string): Record<any, any> => {
  const action = parseRecaptchaAction(method + '_' + url.replace(/\?.*$/, '').replace(/^.*\//, ''));
  return {
    'x-recaptcha-type': 'silent',
    'x-recaptcha-action': action,
    'x-recaptcha-token': token ?? getRecaptchaToken(action),
  };
};

export const getChallengeConfig = (method: Method, url: string, token: string): Record<any, any> => {
  const action = parseRecaptchaAction(method + '_' + url.replace(/\?.*$/, '').replace(/^.*\//, ''));
  return {
    'x-recaptcha-type': 'challenge',
    'x-recaptcha-action': action,
    'x-recaptcha-token': token,
  };
};

export const getSiteKey = (): string => {
  const result = process.env.RECAPTCHA_SILENT_ENABLED === 'true' ? process.env.RECAPTCHA_SITE_KEY : '';
  return result;
};

export const initialiseRecaptcha = async (): Promise<void> => {
  if (!getSiteKey()) {
    return;
  }
  if (!(window as any).grecaptcha) {
    injectRecaptchaScript();
  }
};

export const getRecaptchaToken = async (action: string): Promise<string | null> => {
  const recaptchaInstance = await getRecaptchaInstance();
  if (!recaptchaInstance) {
    return null;
  }
  const token = await recaptchaInstance.execute(getSiteKey(), {
    action: parseRecaptchaAction(action),
  });

  return token;
};

/**
 * https://cloud.google.com/recaptcha-enterprise/docs/actions
 * "Action names are not case-sensitive and they can only contain alphanumeric characters, slashes, and underscores."
 */
export const parseRecaptchaAction = (action: string): string => {
  return action.replace(/[^A-Za-z/_]/g, '');
};

const getRecaptchaInstance = async (): Promise<any> => {
  if (!getSiteKey()) {
    return false;
  }

  await waitforRecaptchaReady();

  return (window as any)?.grecaptcha?.enterprise;
};

const injectRecaptchaScript = (): boolean => {
  const recaptchaScript = document.createElement('script');
  recaptchaScript.src = `${RECAPTCHA_SCRIPT_URL}${getSiteKey()}`;
  const appended = (window as any).document.body.appendChild(recaptchaScript);

  return !!appended;
};

const waitforRecaptchaReady = async (attemptCount = 0) => {
  if (!getSiteKey()) {
    return false;
  }

  const maxAttempts = 5;
  if (typeof (window as any).grecaptcha?.enterprise?.execute === 'function') {
    return true;
  }
  if (attemptCount >= maxAttempts) {
    return false;
  }
  await exponentialDelay(attemptCount);

  return await waitforRecaptchaReady(attemptCount + 1);
};

export const exponentialDelay = async (attempt = 1): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, 8 ** attempt));
