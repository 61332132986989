import jwt_decode from 'jwt-decode';
import HttpClient from 'app/services/http/httpClient';
import { getStorageItem, removeStorageItem, setStorageItem } from '../storage/storage';

const getLoginUrl = async (): Promise<string> => {
  return HttpClient.get('/auth/user/login')
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
};

const getToken = async (params: string): Promise<boolean> => {
  return HttpClient.get(`/auth/user/token?${params}`)
    .then((response) => {
      if (response.data.access_token && response.data.id_token) {
        saveTokensInLocalStorage({
          idToken: response.data.id_token,
          accessToken: response.data.access_token,
        } as MyAccount.Tokens);
      } else {
        return false;
      }

      return true;
    })
    .catch(() => {
      return false;
    });
};

const logout = async (): Promise<void> => {
  const token = getStorageItem('accessToken', 'session');
  if (token) {
    await HttpClient.get(`/auth/user/logout?accessToken=${token}`);
  }

  removeTokensFromLocalStorage();
};

const saveTokensInLocalStorage = (tokens: MyAccount.Tokens): void => {
  setStorageItem('idToken', tokens.idToken, 'local');
  setStorageItem('accessToken', tokens.accessToken, 'session');
};

const removeTokensFromLocalStorage = (): void => {
  removeStorageItem('idToken', 'local');
  removeStorageItem('accessToken', 'session');
};

const getJWTIssuer = (token: unknown): JWTIssuer => {
  const issuer = token['iss'] as string;
  if (process.env.OKTA_ISSUER === issuer) return 'OKTA';
  return 'MyAccount';
};

const isVerifiedCsr = (): boolean => {
  const storageToken = getStorageItem('idToken', 'local');
  return parseIdToken(storageToken)?.issuer === 'OKTA';
};

const parseIdToken = (idToken: JWT): MyAccount.ParsedIdToken | null => {
  try {
    const decodedToken = jwt_decode(idToken);
    if (decodedToken) {
      return {
        issuer: getJWTIssuer(decodedToken),
        myAccountId: decodedToken['sub'] as string,
        jwtExpireTimestamp: decodedToken['exp'] as number,
        jwt: idToken,
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};

export { getLoginUrl, getToken, logout, removeTokensFromLocalStorage, parseIdToken, getJWTIssuer, isVerifiedCsr };
