import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import dayjs from 'dayjs';
import { getStorageItem } from '../storage/storage';
import { parseIdToken } from '../authentication/authentication';
import SessionTimeoutModal from './SessionTimeoutModal';

let countdownInterval;
let timeout;
const debounceTime = 250;
const oneSecInMillis = 1000;
// JWT token is set to 60 minutes from creation
// const jwtDurationInSeconds = 60 * 60;

export const clearSessionData = (): void => {
  sessionStorage.clear();
};

const getExpireTimestamp = (): number => {
  const idToken = getStorageItem('idToken', 'local');

  if (idToken) {
    const parsedIdToken = parseIdToken(idToken);
    return parsedIdToken.jwtExpireTimestamp;
  }

  return null;
};

interface SessionTimeoutProps {
  timeoutIntervalInSeconds: number;
  numberOfAllowedTimeouts: number;
  timeAllowedForDecision: number;
  redirectPath?: string;
}

const SessionTimeout: React.FC<SessionTimeoutProps> = ({
  timeoutIntervalInSeconds,
  numberOfAllowedTimeouts,
  timeAllowedForDecision,
  redirectPath,
}) => {
  const expiresAt = getExpireTimestamp() ?? new Date().getTime() / 1000;
  const totalFormDuration = (numberOfAllowedTimeouts + 1) * (timeoutIntervalInSeconds + timeAllowedForDecision);
  const expireTime = dayjs.unix(expiresAt);
  const timeNow = dayjs();

  const secondsUntilTokenExpiry = expireTime.diff(timeNow, 'seconds');
  const secondsRemaining = Math.min(secondsUntilTokenExpiry, totalFormDuration);
  const idleTimeRemaining = secondsRemaining - timeAllowedForDecision;
  const almostExpired = !!getExpireTimestamp() && idleTimeRemaining <= timeAllowedForDecision;
  const availableTime = idleTimeRemaining > timeoutIntervalInSeconds ? timeoutIntervalInSeconds : idleTimeRemaining;
  const timeoutIntervalInMilliseconds = availableTime > 0 ? availableTime * 1000 : debounceTime;
  const isLastCountdown = secondsRemaining <= timeoutIntervalInSeconds + timeAllowedForDecision;
  const adjustedTimeForDecision = timeAllowedForDecision < secondsRemaining ? timeAllowedForDecision : secondsRemaining;

  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [finalTimeoutModalHasOpened, setFinalTimeoutModalHasOpened] = useState(almostExpired);
  const history = useHistory();

  useEffect(() => {
    let millisecondsUntilHardExpiry = expireTime.diff(timeNow);
    if (millisecondsUntilHardExpiry <= 0) millisecondsUntilHardExpiry = 1;
    const forceLogout = setTimeout(handleLogout, millisecondsUntilHardExpiry);
    return () => {
      clearTimeout(forceLogout);
      if (timeout) clearTimeout(timeout);
      if (countdownInterval) clearInterval(countdownInterval);
    };
  }, []);

  const onTimeOut = () => {
    if (timeout) clearTimeout(timeout);
    if (countdownInterval) clearInterval(countdownInterval);
    timeout = setTimeout(() => {
      if (finalTimeoutModalHasOpened) return;

      if (isLastCountdown) {
        setFinalTimeoutModalHasOpened(true);
      }

      let countDownTimer = adjustedTimeForDecision > 0 ? adjustedTimeForDecision : 0;
      setTimeoutModalOpen(true);
      setTimeoutCountdown(countDownTimer);

      countdownInterval = setInterval(() => {
        if (countDownTimer > 0) {
          setTimeoutCountdown(--countDownTimer);
        } else if (isAuthenticated()) {
          handleLogout();
        }
      }, oneSecInMillis);
    }, 100);
  };

  const handleLogout = () => {
    setTimeoutModalOpen(false);
    history.push(redirectPath);
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearTimeout(timeout);
    clearInterval(countdownInterval);
  };

  const handleDismiss = () => {
    setTimeoutModalOpen(false);
  };

  const isAuthenticated = () => {
    const isUserAuthenticated = getStorageItem('isAuthenticated', 'session');
    return isUserAuthenticated === true;
  };

  const onIdle = () => {
    if (isAuthenticated() && !timeoutModalOpen) {
      onTimeOut();
    }
  };

  useIdleTimer({ onIdle, timeout: timeoutIntervalInMilliseconds, debounce: debounceTime });

  return (
    <>
      {timeoutModalOpen && (
        <SessionTimeoutModal
          countdown={timeoutCountdown}
          onContinue={handleContinue}
          onLogout={handleLogout}
          onDismiss={handleDismiss}
          disableContinue={isLastCountdown}
        />
      )}
    </>
  );
};

export default SessionTimeout;
