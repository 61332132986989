import { edgeSizeLarge, edgeSizeSmall } from './edgeSize';

export default {
  xxmobile: {
    value: 350,
    edgeSize: edgeSizeSmall,
  },
  xmobile: {
    value: 375,
    edgeSize: edgeSizeSmall,
  },
  xxsmall: {
    value: 414,
    edgeSize: edgeSizeSmall,
  },
  xsmall: {
    value: 479,
    edgeSize: edgeSizeSmall,
  },
  small: {
    value: 767,
    edgeSize: edgeSizeSmall,
  },
  medium: {
    value: 1023,
    edgeSize: edgeSizeLarge,
  },
  large: {
    value: 1223,
    edgeSize: edgeSizeLarge,
  },
  xlarge: {
    value: 1224,
    edgeSize: edgeSizeLarge,
  },
};
