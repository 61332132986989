import styled from 'styled-components';
import { Button, InPageAlert } from '@snsw-gel/react';
import { Col, ContentContainer, Heading, Row } from '@snsw/snsw-rjsf';
import global from 'app/theme/styles/global';

const mobileBreakpoint = `${global.breakpoints.small.value}px`;

const StyledContentContainer = styled(ContentContainer)`
  padding: ${global.edgeSize.xxlarge} 10rem;
  position: relative;

  @media (max-width: ${mobileBreakpoint}) {
    padding: ${global.edgeSize.none} ${global.edgeSize.none} ${global.edgeSize.xxxlarge};
  }
`;

const ServiceNotAvailable: React.FC = () => (
  <StyledContentContainer style={{ paddingTop: 0 }}>
    <Row>
      <Col span={12}>
        <Heading level={1}>We’re sorry about this...</Heading>
        <InPageAlert variant="info" title="This service is unavailable at this time" headingLevel="h4">
          <p>
            <Button
              variant="link"
              onClick={() => {
                window.history.go(-1);
              }}
            >
              Back to previous page
            </Button>
          </p>
        </InPageAlert>
      </Col>
    </Row>
  </StyledContentContainer>
);

export default ServiceNotAvailable;
