export default {
  xxxxxsmall: '3rem', // 48px
  xxxxsmall: '6rem', // 96px
  xxxsmall: '12rem', // 192px
  xxsmall: '24rem', // 384px
  xsmall: '25.875rem', // 414px
  small: '30rem', // 480px
  medium: '48rem', // 768px
  large: '64rem', // 1024px
  xlarge: '76.5rem', // 1224px
  full: '100%',
};
