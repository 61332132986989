import CryptoJS from 'crypto-js';

export const encryptBase64 = (value: string): string => Buffer.from(value, 'utf-8').toString('base64');

export const decryptBase64 = (value: string): string => Buffer.from(value, 'base64').toString('utf-8');

export const encryptWithAKey = (value: string, key: string): string => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(value), key);
  } catch (err) {
    return '';
  }
};

export const decryptWithAKey = (value: string, key: string): string => {
  try {
    const decryptedString = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);

    return decryptedString ? JSON.parse(decryptedString) : '';
  } catch (err) {
    return '';
  }
};
