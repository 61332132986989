import { Modal } from '@snsw-gel/react';

const formatMessage = (s: number) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};

interface SessionTimeoutModalProps {
  countdown: number;
  onLogout: () => void;
  onContinue: () => void;
  onDismiss: () => void;
  disableContinue?: boolean;
}

const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({
  countdown,
  onLogout,
  onContinue,
  onDismiss,
  disableContinue,
}) => {
  const buttons = [{ text: 'Logout', onClick: onLogout }];
  const buttonWithContinue = [{ text: 'Continue Session', onClick: onContinue }].concat(buttons);
  const buttonWithDismiss = [{ text: 'Dismiss', onClick: onDismiss }].concat(buttons);

  return (
    <Modal title="Session Timeout" buttons={disableContinue ? buttonWithDismiss : buttonWithContinue}>
      <p>
        {!disableContinue ? (
          <>
            The current session is about to expire in <span>{formatMessage(countdown)}</span> seconds.
          </>
        ) : (
          `
            You have reached the maximum session time limit, please try again. The session will expire in ${formatMessage(
              countdown,
            )} minutes.`
        )}
        {!disableContinue && <span> Would you like to continue the session?</span>}
      </p>
    </Modal>
  );
};

export default SessionTimeoutModal;
