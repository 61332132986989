import { openModal } from 'app/services/events/EventService';

interface ModalButtons {
  close?: {
    text?: string;
    handler?: () => void;
  };
  confirm?: {
    text?: string;
    handler?: () => void;
  };
}

export interface ResponseBody {
  data?: any;
  error?: string;
}

export const extractDataOrOpenErrorModal = (response: ResponseBody, modalButtons?: ModalButtons): any | null => {
  if (response.error) {
    openModal('ERROR', {
      message: response.error,
      buttons: modalButtons,
    });
    return 'error';
  } else {
    return response.data;
  }
};
