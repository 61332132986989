import { useEffect } from 'react';

const ReCAPTCHAChallenge = ({ grecaptcha }: { grecaptcha: any }): JSX.Element => {
  useEffect(() => {
    grecaptcha?.enterprise.ready(() => {
      grecaptcha.enterprise.render(document.getElementsByClassName('recaptcha-container')[0], {
        sitekey: `${process.env.RECAPTCHA_CHALLENGE_SITE_KEY}`,
      });
    });
  }, []);

  return <div data-testid="recaptcha-placeholder" className="recaptcha-container"></div>;
};

export default ReCAPTCHAChallenge;
