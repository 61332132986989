import breakpoints from 'app/theme/styles/breakpoints';
import edgeSize from 'app/theme/styles/edgeSize';
import borderSize from 'app/theme/styles/borderSize';
import size from 'app/theme/styles/size';
import font from 'app/theme/styles/font';
// import colors from 'app/theme/styles/colors';

const config = {
  breakpoints,
  edgeSize,
  size,
  font,
  // colors,
  borderSize,
};

export default config;
