import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import App from './App';

dayjs.extend(utc);
dayjs.extend(timezone);

const tagManagerArgs = {
  gtmId: 'GTM-TMQQTN',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
