import { FormHeader } from '@snsw-gel/react';
import { getStepList, getParentRouteSet } from 'app/services/routes/RouteService';
import { Route } from 'app/routes/types';
import { StyledStepperWrapper } from './DynamicFormHeader.styled';

const DynamicFormHeader: React.FC<DynamicFormHeaderProps> = (props) => {
  const hasHeader = props.route.dynamic && props.route.stepper;
  return hasHeader ? (
    <StyledStepperWrapper data-id="stepper">
      <FormHeader
        title={getParentRouteSet(props.route).title}
        label={getParentRouteSet(props.route).title}
        stepsList={hasHeader && getStepList(props.route)}
      />
    </StyledStepperWrapper>
  ) : null;
};

type DynamicFormHeaderProps = {
  route: Route;
};

export default DynamicFormHeader;
