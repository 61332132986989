/* eslint-disable react/display-name */
import { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from '@snsw-gel/react';
import useEvent from 'app/hooks/useEvent';

const Message = styled.p`
  margin-right: 0.5em;
`;

const StyledLink = styled(Button)`
  &:focus {
    outline: none;
  }
`;

const CommonErrorModal = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please come back later.');
  const [closeButtonText, setCloseButtonText] = useState('Close');
  const [onCloseHandler, setOnCloseHandler] = useState<(args?: any) => any>();

  const closeModal = () => {
    !!onCloseHandler && onCloseHandler();
    setOpenModal(false);
  };

  /* istanbul ignore next */
  useEvent('OPEN_ERROR_MODAL', (event) => {
    const eventErrorMessage = event.detail.message;
    setErrorMessage(eventErrorMessage);
    const closeHandler = event.detail?.buttons?.close?.handler;
    const customisedCloseButtonText = event.detail?.buttons?.close?.text;
    if (closeHandler) setOnCloseHandler(() => () => closeHandler());
    if (customisedCloseButtonText) setCloseButtonText(customisedCloseButtonText);
    setOpenModal(true);
  });

  return (
    <>
      {!!openModal && (
        <Modal title="Sorry, something went wrong" buttons={[{ text: closeButtonText, onClick: closeModal }]}>
          <div>
            <Message>{errorMessage}</Message>
            <StyledLink theme="link">
              <a href="https://www.service.nsw.gov.au/contact-us" target="_blank" rel="noopener, noreferrer">
                Contact us
              </a>
            </StyledLink>
            if the problem continues.
          </div>
        </Modal>
      )}
    </>
  );
};

export default CommonErrorModal;
