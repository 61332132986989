import components from 'app/pages';
import { Route, DynamicallyLoadedRouteSet, DynamicallyLoadedRoute } from 'app/routes/types';

export const routeCache: RouteDetailsCache = {
  indexes: {},
  stepLists: {},
  parents: {},
};

const routeMapper = (route: DynamicallyLoadedRoute, routeSet: DynamicallyLoadedRouteSet): Route => {
  if (!(route.component in components)) {
    throw `Route component '${route.component}' not found. Did you add it to the index file?`;
  }

  return {
    component: components[route.component],
    page: route.page,
    path: route.path,
    basePath: routeSet.basePath,
    private: route.private,
    exact: route.exact,
    dynamic: true,
    stepper: route.stepper == null ? true : route.stepper, // Default is showing the stepper
    ...(route.stepOrder !== undefined ? { stepOrder: route.stepOrder } : {}),
  };
};

export const getAllRoutes = (routeSets: Array<DynamicallyLoadedRouteSet>): Array<Route> => {
  const knownRoutes = [];
  routeSets.forEach((routeSet) => {
    routeCache.indexes[routeSet.basePath] = {};
    routeCache.stepLists[routeSet.basePath] = [];
    // We need to map our routes so we have more information about them cached
    // Can't use index, because we skip some routes (any without steppers)
    let stepperRouteIndex = 0;
    knownRoutes.push(
      ...routeSet.routes.map((route) => {
        const mappedRoute = routeMapper(route, routeSet);
        if (mappedRoute.stepper) {
          // Where in the set the route is (So previous steps are marked as completed, and upcoming are marked as todo)
          // If an explicit order is given use the explict order, else use a run-time counter.
          routeCache.indexes[routeSet.basePath][route.path] = route.stepOrder ?? stepperRouteIndex;

          stepperRouteIndex++;

          if (route.page.structure.stepHeading) {
            // Each heading in the routeSet (To build the step labels)
            // this is the key to enable multiple pages for one step as only one of them should have
            // the step header
            routeCache.stepLists[routeSet.basePath].push(route.page.structure.stepHeading);
          }
        }
        // The entire routeSet, so any route can find which set it belongs to (To build the overall page title in the stepper)
        routeCache.parents[route.path] = routeSet;
        return mappedRoute;
      }),
    );
  });

  return knownRoutes;
};

// This returns a stepList, as used in the Kiama framework FormHeader. No types provided to import :(
export const getStepList = (route: Route): Array<KiamaFormHeaderStep> => {
  const currentIndex = routeCache.indexes[route.basePath][route.path];

  return routeCache.stepLists[route.basePath].map((stepHeading: string, index: number) => ({
    content: stepHeading,
    status: index < currentIndex ? 'completed' : index == currentIndex ? 'current' : 'todo',
  }));
};

export const getParentRouteSet = (route: Route): DynamicallyLoadedRouteSet => {
  return routeCache.parents[route.path];
};

type RouteDetailsCache = {
  indexes: { [basePath: string]: { [path: string]: number } };
  stepLists: { [basePath: string]: Array<string> };
  parents: { [path: string]: DynamicallyLoadedRouteSet };
};

type KiamaFormHeaderStep = {
  content: string;
  status: string;
};
