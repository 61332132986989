import { useEffect } from 'react';

type TEventName = string | keyof WindowEventMap;
type TEventOptions = boolean | AddEventListenerOptions;
type TEventListener = EventListenerOrEventListenerObject | ((this: Window, ev: unknown) => any);

const useEvent = (event: TEventName, listener: TEventListener, options?: TEventOptions): void => {
  useEffect(() => {
    // initiate the event listener
    window.addEventListener(event, listener, options);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, listener);
    };
  }, [event, listener, options]);
};

export default useEvent;
