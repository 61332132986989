import { ActionTypes, RJSFFormData } from './reducer';

export const setFlowState = (flowState: unknown): Action => ({
  type: ActionTypes.SET_FLOW,
  payload: flowState,
});

export const clearFlowState = (): Action => ({
  type: ActionTypes.CLEAR_FLOW,
});

export const setFormDataState = (formData: RJSFFormData): Action => ({
  type: ActionTypes.SET_FORM_DATA,
  payload: {
    formData,
  },
});

export const setConfirmedBookingState = (confirmedBooking: CommonTypes.ConfirmedBooking): Action => ({
  type: ActionTypes.SET_CONFIRMED_BOOKING,
  payload: {
    confirmedBooking,
  },
});
