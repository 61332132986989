import { useEffect } from 'react';
import { Route } from 'react-router-dom';

interface BaseRouteProps {
  render: () => JSX.Element;
  pageTitle?: string;
  children?: React.ReactNode;
}

const BaseRoute: React.FC<BaseRouteProps> = ({ ...otherProps }) => {
  useEffect(() => {
    if (otherProps.pageTitle) {
      document.querySelector('title').textContent = otherProps.pageTitle;
    }

    // For accessibility, find the H1 tag and give it a tabIndex of -1 which
    // makes it focusable.   Then focus it.  This will allow a screen-reader
    // to understand and read the H1 form title.
    const h1 = document.querySelector('h1');
    if (h1) {
      h1.tabIndex = -1;
      h1.autofocus = true;
    }
  });

  return <Route {...otherProps} />;
};
export default BaseRoute;
