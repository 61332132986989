import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const isSameDates = (
  date: string | number | Date | dayjs.Dayjs,
  comparedDate: string | number | Date | dayjs.Dayjs,
): boolean => {
  return dayjs(date).isSame(dayjs(comparedDate));
};

export const isTheSameDay = (date: Dayjs, comparedDate: Dayjs): boolean => {
  return date.format('YYYYMMDD') === comparedDate.format('YYYYMMDD');
};

export const isDateInRange = (date: Dayjs, from: Dayjs, to: Dayjs): boolean => {
  return !(date.isAfter(to) || date.isBefore(from));
};

export const generateDateRange = (from: Date | Dayjs, numberElements: number): Dayjs[] => {
  return [...Array(numberElements).keys()].map((number) => dayjs(from).add(number, 'days'));
};

export const convertToIsoString = (dateTime: Dayjs): string => {
  return `${dateTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
};
