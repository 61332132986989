export interface ModalDetail {
  title?: string;
  message?: string;
  buttons?: {
    close?: {
      text?: string;
      handler?: ((...args: any) => void) | ((...args: any) => Promise<void>);
    };
    confirm?: {
      text?: string;
      handler?: ((...args: any) => void) | ((...args: any) => Promise<void>);
    };
  };
}

type ModalType = 'ERROR' | 'INFO' | 'RECAPTCHA';

export const openModal = (modalType: ModalType, modalDetail: ModalDetail): void => {
  const customEvent = new CustomEvent(`OPEN_${modalType}_MODAL`, { detail: modalDetail });
  window.dispatchEvent(customEvent);
};
