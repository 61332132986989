import { lazy } from 'react';

export default {
  'app/pages/common/landing/Landing': lazy(() => import('app/pages/common/landing/Landing')),
  'app/pages/common/findALocation/FindALocation': lazy(() => import('app/pages/common/findALocation/FindALocation')),
  'app/pages/common/interpreterService/InterpreterService': lazy(
    () => import('app/pages/common/interpreterService/InterpreterService'),
  ),
  'app/pages/common/userDetails/UserDetails': lazy(() => import('app/pages/common/userDetails/UserDetails')),
  'app/pages/common/rjsf/RJSF': lazy(() => import('app/pages/common/rjsf/RJSF')),
  'app/pages/common/confirmation/Confirmation': lazy(() => import('app/pages/common/confirmation/Confirmation')),
};
