import { Grommet, ThemeType } from 'grommet';
import { ThemeProvider } from 'styled-components';
import { theme as kiamaFrameworkTheme } from '@snsw-gel/react';
import customTheme from 'app/theme/custom';

interface Props {
  theme?: ThemeType;
  children: React.ReactNode;
}

const AppThemeProvider: React.FC<Props> = ({ theme, children }) => {
  return (
    <Grommet theme={theme} plain>
      <ThemeProvider theme={kiamaFrameworkTheme}>{children}</ThemeProvider>
    </Grommet>
  );
};

AppThemeProvider.defaultProps = {
  theme: customTheme,
};

export default AppThemeProvider;
