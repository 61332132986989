/* eslint-disable react/display-name */
import { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '@snsw-gel/react';
import { tokens } from '@snsw-gel/react';
import useEvent from 'app/hooks/useEvent';
import ReCAPTCHAChallenge from '../common/reCAPTCHA/reCAPTCHAChallenge';

const ErrorText = styled.p`
  color: ${tokens.colors.brand.snswPrimaryRed};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em;
`;

const ReCAPTCHAModal = (): JSX.Element => {
  const grecaptcha = (window as any).grecaptcha;

  const [openModal, setOpenModal] = useState(false);

  const [onConfirmHandler, setOnConfirmHandler] = useState<(args: any) => (...args: any) => void>();
  const [onCloseHandler, setOnCloseHandler] = useState<(args?: any) => any>();
  const [triedContinue, setTriedContinue] = useState(false);

  const closeModal = () => {
    !!onCloseHandler && onCloseHandler();
    setOpenModal(false);
  };

  const confirmModal = () => {
    const token = grecaptcha.enterprise.getResponse();
    if (token) {
      !!onConfirmHandler && onConfirmHandler(token);
      setOpenModal(false);
      setTriedContinue(false);
    } else {
      setTriedContinue(true);
    }
  };

  /* istanbul ignore next */
  useEvent('OPEN_RECAPTCHA_MODAL', (event) => {
    const closeHandler = event.detail?.buttons?.close?.handler;
    const confirmHandler = event.detail?.buttons?.confirm?.handler;
    if (closeHandler) setOnCloseHandler(() => () => closeHandler());
    if (confirmHandler) setOnConfirmHandler(() => (token) => confirmHandler(token));
    setOpenModal(true);
  });

  return (
    <>
      {!!openModal && (
        <Modal
          title={'Please complete the following to continue'}
          buttons={[
            { text: 'Continue', onClick: confirmModal },
            { text: 'Cancel', onClick: closeModal },
          ]}
        >
          <StyledWrapper>
            {triedContinue && <ErrorText>Please complete the following challenge to continue.</ErrorText>}
            <ReCAPTCHAChallenge grecaptcha={grecaptcha} />
          </StyledWrapper>
        </Modal>
      )}
    </>
  );
};

export default ReCAPTCHAModal;
