import { BrowserRouter } from 'react-router-dom';
import AppThemeProvider from 'app/components/providers/AppThemeProvider';
import CommonErrorModal from 'app/components/modals/CommonErrorModal';
import CommonInfoModal from 'app/components/modals/CommonInfoModal';
import ScrollToTop from 'app/components/layout/ScrollToTop';
import AppRouter from 'app/routes';
import Auth from 'app/sharedServices/authentication/AuthContext';
import { FlowStateProvider } from 'app/contexts/FlowStateContext';
import { ReCAPTCHAPolicy } from './components/common/reCAPTCHA/reCAPTCHAPolicy';
import ReCAPTCHAModal from './components/modals/ReCAPTCHAModal';

const App: React.FC = () => {
  return (
    <AppThemeProvider>
      <BrowserRouter>
        <Auth>
          <FlowStateProvider>
            <ScrollToTop />
            <CommonInfoModal />
            <CommonErrorModal />
            <ReCAPTCHAModal />
            <AppRouter />
            <ReCAPTCHAPolicy />
          </FlowStateProvider>
        </Auth>
      </BrowserRouter>
    </AppThemeProvider>
  );
};

export default App;
