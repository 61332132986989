const defaultFont = {
  family: 'Gotham,Arial,sans-serif',
  height: '125%',
  size: '16px',
  weight: {
    thin: 400,
    semibold: 600,
    bold: 700,
  },
};

export default defaultFont;
