export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};
export const getAllQueryParam = () => {
  return new URLSearchParams(window.location.search);
};

export const getProfileConnectURL = (transactionType): string => {
  return process.env.PROFILE_CONNECT_URL.replace('TRANSACTION_TYPE', transactionType);
};
