import { lazy } from 'react';
import { Route } from 'app/routes/types';

export enum ERoutesPathCommon {
  AUTH_CALLBACK = '/auth/callback',
  HOME = '/',
  ERROR = '/404',
  LOGOUT = 'logout',
  MANAGE_BOOKINGS = '/manage-bookings',
  PROFILE_CONNECT_URL_CALLBACK = '/profile-connect/:status',
  UNDER_MAINTENANCE = '/under-maintenance',
  CATEGORY_WITH_AGENCY = '/categories/:category/:agency',
  CATEGORY = '/categories/:category',
  SEND_REMINDER = '/bookings-reminder',
}

export const commonRoutes: Route[] = [
  {
    component: lazy(() => import('app/pages/authenticate/Authenticate')),
    path: ERoutesPathCommon.AUTH_CALLBACK,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/error/404')),
    path: ERoutesPathCommon.ERROR,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/home/Home')),
    path: ERoutesPathCommon.HOME,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/home/Home')),
    path: '/services',
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/profileConnect/ProfileConnectListener')),
    path: '/profile-connect/:status',
    basePath: '/',
    private: true,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/manageBookings/ManageBookings')),
    path: ERoutesPathCommon.MANAGE_BOOKINGS,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/manageBookings/SendReminder')),
    path: ERoutesPathCommon.SEND_REMINDER,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/logout/Logout')),
    path: '/*/logout',
    private: false,
    basePath: '/',
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/logout/Logout')),
    path: '/logout',
    private: false,
    basePath: '/',
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/maintenance/UnderMaintenance')),
    path: ERoutesPathCommon.UNDER_MAINTENANCE,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/category/Category')),
    path: ERoutesPathCommon.CATEGORY_WITH_AGENCY,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/category/Category')),
    path: ERoutesPathCommon.CATEGORY,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
  },
];
