import { useContext, useEffect, useState } from 'react';
import { ComponentLoader } from '@snsw-gel/react';
import SessionTimeout from 'app/sharedServices/sessionTimeout/SessionTimeout';
import { ERoutesPathCommon } from 'app/routes/common/routes';
import { Route } from 'app/routes/types';
import BaseRoute from '../baseRoute/BaseRoute';
import { getStorageItem, setStorageItem } from '../storage/storage';
import { AuthContext } from './AuthContext';
import { getLoginUrl } from './authentication';

export type UserInfo = {
  id: string;
  email: string;
};

const initFakeUser = (setIsAuthenticated) => {
  const fakeUserInfo: UserInfo = {
    id: 'f6b201fe-b762-4138-b4f9-adfc4c41bde9',
    email: 'joe.bloggs@service.nsw.gov.au',
  };
  if (!getStorageItem('isAuthenticated', 'session')) {
    setStorageItem('userInfo', JSON.stringify(fakeUserInfo), 'session');
    setStorageItem('isAuthenticated', true, 'session');
    setIsAuthenticated(true);
  }
};

interface Props {
  route?: Route;
  basePath?: string;
  path?: string;
  render: () => React.ReactNode;
}

const PrivateRoute = (props: Props): JSX.Element => {
  const useOfflineMyAccount = process.env.REACT_APP_OFFLINE_MY_ACCOUNT === 'true';
  const { checkIsAuthenticated } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(checkIsAuthenticated());
  const sessionIdleTimeout = 600;
  const numberOfAllowedTimeouts = 2;
  const timeAllowedForDecision = 60;
  const redirectOnSessionTimeout = `${props.basePath}${ERoutesPathCommon.LOGOUT}`;

  const Login = () => {
    useEffect(() => {
      const launchMyAccount = async () => {
        setStorageItem('nextPath', props.path, 'session');
        try {
          const loginUrl = await getLoginUrl();
          if (loginUrl && props.path == location.pathname) {
            window.location.assign(loginUrl);
          }
        } catch (error) {
          console.error('Could not get login url');
        }
      };
      useOfflineMyAccount ? initFakeUser(setIsAuthenticated) : launchMyAccount();
    }, []);
    return <ComponentLoader label="Authenticating..." fullPage />;
  };
  return (
    <BaseRoute
      {...props}
      render={() =>
        isAuthenticated ? (
          <>
            <SessionTimeout
              timeoutIntervalInSeconds={sessionIdleTimeout}
              numberOfAllowedTimeouts={numberOfAllowedTimeouts}
              redirectPath={redirectOnSessionTimeout}
              timeAllowedForDecision={timeAllowedForDecision}
            />
            {props.render()}
          </>
        ) : (
          <Login />
        )
      }
    />
  );
};

export default PrivateRoute;
