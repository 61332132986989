import { createContext } from 'react';
import { decryptWithAKey } from 'app/services/utils/encrypt';
import { getAllQueryParam, getQueryParam } from 'app/services/utils/url';
import { getStorageItem, removeStorageItem, setStorageItem } from '../storage/storage';
import { getToken, logout, parseIdToken } from './authentication';

export const AuthContext = createContext<AuthContext.Context>(null);

export default function Auth({ children }: { children?: React.ReactNode }): JSX.Element {
  const oktaToken = getQueryParam('okta_token');
  if (oktaToken) {
    const allParamsWithoutOkta = getAllQueryParam();
    allParamsWithoutOkta.delete('okta_token');
    setStorageItem('idToken', oktaToken, 'local');
    setStorageItem('userLoginTimestamp', new Date().toString(), 'session');
    setStorageItem('isAuthenticated', true, 'session');
    const pagePathWithoutOktaToken = `${window.location.pathname}?${allParamsWithoutOkta.toString()}`;
    window.history.pushState({}, document.title, pagePathWithoutOktaToken);
  }

  const getUserId = (): string => {
    const idToken = getStorageItem('idToken', 'local');

    if (idToken) {
      const parsedIdToken = parseIdToken(idToken as JWT);

      return parsedIdToken.myAccountId;
    }

    return null;
  };

  const checkIsAuthenticated = () => {
    return getStorageItem('isAuthenticated', 'session') === true;
  };

  const userInfo = () => {
    const key = getUserId();
    return key ? JSON.parse(decryptWithAKey(getStorageItem('userInfo', 'session'), key).toString()) : null;
  };

  const authenticateUser = async (urlParams) => {
    let userAuthenticated = false;
    const token = await getToken(urlParams);
    if (token) userAuthenticated = true;
    setStorageItem('isAuthenticated', true, 'session');
    return userAuthenticated;
  };

  const removeUserInfo = async () => {
    removeStorageItem('userInfo', 'session');
    removeStorageItem('isAuthenticated', 'session');
    await logout();
  };

  return (
    <AuthContext.Provider value={{ checkIsAuthenticated, authenticateUser, userInfo, removeUserInfo, getUserId }}>
      {children}
    </AuthContext.Provider>
  );
}
