import dayjs from 'dayjs';
import HttpClient from 'app/services/http/httpClient';
import { getStorageItem } from 'app/sharedServices/storage/storage';
import { BookingTypeValues } from 'app/components/common/locationFilters/bookingTypeFilters/BookingTypeFilters';
import { getChallengeConfig } from 'app/services/recaptcha/recaptcha';
import { convertToIsoString } from '../../utils/date';
import { ResponseBody } from '../dataExtraction';

export const getBookingTimes = async (
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  locationId: string,
  serviceId: string,
  interactionType: string,
  needInterpreter: string,
  postcode: string,
  overrideLeadTime = false,
  resourceId?: string,
  signal?: typeof AbortController.prototype.signal,
): Promise<ResponseBody> => {
  const startDateTime = convertToIsoString(from);
  const endDateTime = convertToIsoString(to);
  return HttpClient.get(`/locations/${locationId}/booking-times`, {
    params: {
      ...(resourceId ? { resourceId } : {}),
      serviceId,
      interactionType,
      startDateTime,
      endDateTime,
      needInterpreter,
      postcode,
      overrideLeadTime,
    },
    signal,
  });
};

export const getConfirmedBooking = async (bookingId: string, referenceId: string): Promise<ResponseBody> => {
  return HttpClient.get(`/bookings/${bookingId}/${referenceId}`);
};

export const cancelBooking = async (bookingId: string, referenceId: string): Promise<ResponseBody> => {
  return HttpClient.delete(`/bookings/${bookingId}/${referenceId}`, null);
};

export const bookingCancellationFeedback = async (
  bookingId: string,
  referenceId: string,
  data: { cancellationReason: string; cancellationReasonOther?: string },
): Promise<ResponseBody> => {
  return HttpClient.post(`/bookings/${bookingId}/${referenceId}`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
};

export const confirmBooking = async (
  bookingId: string,
  serviceId: string,
  data: CommonTypes.PersonalDetails = {},
): Promise<ResponseBody> => {
  return HttpClient.patch(
    `/bookings/${bookingId}/confirm?serviceId=${serviceId}`,
    { data },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    },
  );
};

export const reserveBooking = async (
  startDateTime: string,
  locationId: string,
  email: string | undefined,
  phone: string,
  postcode: string,
  serviceId: string,
  interactionType: string,
  needInterpreter: string,
  previousId?: string,
  previousReferenceId?: string,
  overrideLeadTime = false,
  bookMe?: string,
  reCAPTCHAChallengeToken: string | null = null,
): Promise<ResponseBody> => {
  const isGuestBooking = !!reCAPTCHAChallengeToken;
  const data = {
    ...(phone ? { phone } : {}),
    ...(email ? { email } : {}),
    ...(postcode ? { postcode } : {}),
    ...(bookMe ? { bookMe } : {}),
    startDateTime: convertToIsoString(dayjs(startDateTime)),
    interactionType,
    madeByCsr: getStorageItem('isCsr', 'session') === true ? 'Yes' : 'No',
    localStartDateTime: dayjs(startDateTime).local().format('llll'),
    needInterpreter,
    previousId,
    previousReferenceId,
    isGuestBooking,
    overrideLeadTime,
  };
  const url = `/locations/${locationId}/services/${serviceId}/bookings`;
  return HttpClient.post(url, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(isGuestBooking ? getChallengeConfig('POST', url, reCAPTCHAChallengeToken) : {}),
    },
  });
};

export const sendBookingReminders = async (emailOrPhone: string, lastName: string): Promise<ResponseBody> => {
  const data = {
    lastName,
    emailOrPhone,
  };
  return HttpClient.post(`/bookings/reminder`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
};

export const getServiceInfo = async (service: string): Promise<ResponseBody> => {
  return HttpClient.get(`/services/${service}`);
};

export const getCategoryServiceInfo = async (category: string, agency?: string | undefined): Promise<ResponseBody> => {
  return HttpClient.get(`/categories/${category}${agency ? '/' + agency : ''}`);
};

export const getLocationsByService = async (
  serviceId: string,
  sortByOption = 'distance',
  lat = 0,
  lng = 0,
  pageNumber = 0,
  interactionTypes: BookingTypeValues[] = [],
  signal?: AbortSignal,
): Promise<ResponseBody> => {
  let requestUrl = `/service/${serviceId}/locations`;
  if (lat && lng && pageNumber) {
    requestUrl += `/${lat}/${lng}/${pageNumber}`;
  }
  requestUrl += `?sortBy=${sortByOption}`;
  requestUrl += `&interactionTypes=${interactionTypes.join(',')}`;
  return HttpClient.get(requestUrl, { signal });
};

export const getUserProfile = async (accessToken: string, sessionId: string): Promise<ResponseBody> => {
  return HttpClient.get(`/profileConnect/profile/${sessionId}`, {
    params: { accessToken },
  });
};

export const getBusinessList = async (accessToken: string, sessionId: string): Promise<ResponseBody> => {
  return HttpClient.get(`/businessConnect/getBusinessList/${sessionId}`, {
    params: { accessToken },
  });
};
