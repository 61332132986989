import { ContentContainer, Row, SectionPage } from '@snsw-gel/react';
import { Col } from '@snsw/snsw-rjsf';
export const ReCAPTCHAPolicy: React.FunctionComponent<any> = () => (
  <ContentContainer>
    <Row>
      <Col span={12}>
        <SectionPage>
          <small>
            This site is protected by reCAPTCHA and the Google{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">
              Terms of Service
            </a>{' '}
            apply.
          </small>
        </SectionPage>
      </Col>
    </Row>
  </ContentContainer>
);
